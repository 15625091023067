import { Col, Image, Row } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import React, { Fragment } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import Back from '../assets/Icons/ic_back.svg';

const SiteHeader = () => {

    const { pathname: path } = useLocation();
    const history = useHistory();
    console.log(path);
    const pathArray = [
        { path: '/home', title: 'Profile' }, { path: '/login', title: '' }, { path: '/register', title: '' },
        { path: '/profile', title: 'Profile' }, { path: '/edit-profile', title: 'EDIT PROFILE' },
        { path: '/change-password', title: 'Change passowrd' }, { path: '/location', title: 'Location' }, 
        { path: '/add-location', title: 'add location' },
        { path: '/edit-location', title: 'Edit location' }, { path: '/services', title: 'Services' },
        { path: '/images', title: 'Images' }, { path: '/videos', title: 'Videos' }, { path: '/location-detail', title: 'Location Detail' },
        { path: '/chat', title: 'Messages' },{ path: '/messages', title: 'Messages' }];
    const p = pathArray.find(i => i.path === path);
    console.log(p)
    return (
        <Fragment>
            <Header style={{ padding: '0 20px', position: 'fixed', zIndex: 2, width: '100%' }} >
                <Row >
                    <Col span={5}>
                        <Image src={Back}  onClick={()=>history.goBack()}/>
                    </Col>
                    <Col span={14}>
                        <div className='header-title'>

                            {p?.title}
                        </div>
                    </Col>
                    <Col span={5}>
                       
                    </Col>

                </Row>
            </Header>
        </Fragment>
    )
}

export default SiteHeader;