import { Col, Image, Row } from 'antd'
import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom';
import Braz from '../../assets/Icons/ic_brazillian.svg';

const Location = () => {
    const history =useHistory();
    return (
        <Fragment>
            <section className='mt-5'>
                <Row gutter={[0,16]} justify='center'>

{[1,2,3,4,5].map((item,ind)=>(

                    <Col span={22} key={ind} onClick={()=>history.push('location-detail')}>
                        <div className='location-card'>
                            <div className='title mb-1' style={{width:'95%'}}>
                            Brazilian Top Team Long Beach Jiu - Jitsu & MMA
                            </div>
                            <Row>
                                <Col span={16}>
                                    <div className='text-truncate txt-light' style={{width:'95%'}}>2680 Dawson Ave, Signal Hill Jujitua School</div>
                                    <div className='txt-light'>Jujitua School</div>
                                </Col>
                                <Col span={8} className='text-center'>
                                    <Image src={Braz} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
))}
                   
                </Row>
              
            </section>
            <section className='mt-3'>
                <Row justify='center' >
                   
                    <Col span={22}>
                    <button onClick={()=>history.push('/add-location')} className='submit-btn green-bg mb-3'>ADD LOCATION</button>
                    </Col>
                </Row>
            </section>
        </Fragment>
    )
}

export default Location
