import React from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import AddLocation from "./screens/trainer/addLocation";
import ChangePassword from "./screens/trainer/changePassword";
import Chat from "./screens/trainer/chat";
import EditLocation from "./screens/trainer/editLocation";
import EditProfile from "./screens/trainer/editProfile";
import Home from "./screens/trainer/home";
import Images from "./screens/trainer/images";
import Location from "./screens/trainer/location";
import LocationDetail from "./screens/trainer/locationDetail";
import Login from "./screens/trainer/login";
import Messages from "./screens/trainer/messages";
import Profile from "./screens/trainer/profile";
import Register from "./screens/trainer/register";
import Services from "./screens/trainer/services";
import Videos from "./screens/trainer/videos";
import MainTemplate from "./templates/mainTemplate";
import PreLoginTemplate from "./templates/preloginTemplate";

function App() {
  return (

  
      <Switch>
      
        
       
        <Route
          exact
          path="/"
          render={(props) => (
            <PreLoginTemplate path="/" component={Login} exact />
          )}
        />
        <Route
          exact
          path="/register"
          render={(props) => (
            <PreLoginTemplate path="/register" component={Register} exact />
          )}
        />
         <Route
          exact
          path="/home"
          render={(props) => <MainTemplate path="/home" component={Home} exact />}
        />
         <Route
          exact
          path="/profile"
          render={(props) => <MainTemplate path="/profile" component={Profile} exact />}
        />
         <Route
          exact
          path="/edit-profile"
          render={(props) => <MainTemplate path="/edit-profile" component={EditProfile} exact />}
        />
         <Route
          exact
          path="/change-password"
          render={(props) => <MainTemplate path="/change-password" component={ChangePassword} exact />}
        />
         <Route
          exact
          path="/location"
          render={(props) => <MainTemplate path="/location" component={Location} exact />}
        />
         <Route
          exact
          path="/add-location"
          render={(props) => <MainTemplate path="/add-location" component={AddLocation} exact />}
        />
         <Route
          exact
          path="/edit-location"
          render={(props) => <MainTemplate path="/edit-location" component={EditLocation} exact />}
        />
         <Route
          exact
          path="/services"
          render={(props) => <MainTemplate path="/services" component={Services} exact />}
        />
         <Route
          exact
          path="/images"
          render={(props) => <MainTemplate path="/images" component={Images} exact />}
        />
         <Route
          exact
          path="/videos"
          render={(props) => <MainTemplate path="/videos" component={Videos} exact />}
        />
         <Route
          exact
          path="/location-detail"
          render={(props) => <MainTemplate path="/location-detail" component={LocationDetail} exact />}
        />
         <Route
          exact
          path="/chat"
          render={(props) => <MainTemplate path="/chat" component={Chat} exact />}
        />
         <Route
          exact
          path="/messages"
          render={(props) => <MainTemplate path="/messages" component={Messages} exact />}
        />
      </Switch>
  

  );
}

export default App;
