import { Button, Checkbox, Col, Form, Image, Input, Row, Space } from 'antd'
import React, { Fragment } from 'react'
import Logo from '../../assets/Icons/ic_logo_login.svg';
import User from '../../assets/Icons/ic_user.svg';
import Pass from '../../assets/Icons/ic_password.svg';
import Business from '../../assets/Icons/ic_business.svg';
import Flag from '../../assets/Icons/ic_flag.svg';
import { MdAlternateEmail } from 'react-icons/md';
import Picture from '../../assets/Icons/ic_placeholder.svg';
import Camera from '../../assets/Icons/ic_camera.svg';


const FlagIcon = (
    <div className='d-flex align-items-center'>
        <Image src={Flag} /><span style={{ color: '#1C1C1E', marginLeft: 3 }}>+1</span>
    </div>
)
const AddLocation = () => {
    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Fragment>
            <section className='mb-5'>
                <div className='text-center mt-3 '>
                    <div className='d-inline-block position-relative'>
                        <Image src={Picture} />
                        <div className='camera'>
                            <Image src={Camera} />
                        </div>
                    </div>
                    <h5 className='mt-2'>Johnny Lawrence</h5>
                </div>

            </section>
            <Row align='center' justify='center'>
                <Col span={22}>

                    <div className='form w-100'>
                        <Form
                            name="register"
                            initialValues={{}}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item

                                name="name"

                            // rules={[{ required: true, message: 'Please input your username!' }]}
                            >

                                <Input placeholder='Name'
                                />
                            </Form.Item>

                            <Form.Item

                                name="address1"

                            // rules={[{ required: true, message: 'Please input your password!' }]}
                            >

                                <Input placeholder='Address 1'
                                />

                            </Form.Item>
                            <Form.Item

                                name="address2"

                            // rules={[{ required: true, message: 'Please input your password!' }]}
                            >

                                <Input placeholder='Address 2'
                                />

                            </Form.Item>
                            <Form.Item

                                name="city"

                            // rules={[{ required: true, message: 'Please input your password!' }]}
                            >

                                <Input placeholder='City'
                                />

                            </Form.Item>



                            <Form.Item

                                name="state"

                            // rules={[{ required: true, message: 'Please input your password!' }]}
                            >

                                <Input placeholder='State'
                                />

                            </Form.Item>
                            <Form.Item

                                name="country"

                            // rules={[{ required: true, message: 'Please input your password!' }]}
                            >

                                <Input placeholder='Country'
                                />

                            </Form.Item>
                            <Form.Item

                                name="zipCode"

                            // rules={[{ required: true, message: 'Please input your password!' }]}
                            >

                                <Input placeholder='Zip Code'
                                />

                            </Form.Item>


                            <Form.Item

                                name="number"

                            // rules={[{ required: true, message: 'Please input your password!' }]}
                            >

                                <Input placeholder='(617) 397 - 8483'
                                    prefix={FlagIcon} />

                            </Form.Item>






                            <Form.Item >
                                <button className='submit-btn'>
                                    ADD LOCATION
                                </button>

                            </Form.Item>
                        </Form>
                    </div>
                </Col>
            </Row>

        </Fragment>
    )
}

export default AddLocation;
