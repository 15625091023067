import React,{Fragment, useEffect} from 'react';
import { Layout } from 'antd';
import { Route, Redirect } from "react-router-dom";
// import { useSelector } from "react-redux";

// import SiteFooter from './siteFooter';

import SiteHeader from '../components/siteHeader';


const { Footer, Content } = Layout;




const MainTemplate = (props) => {
    const { exact, component, path } = props;
   
  
    return (
        <Fragment>
            <SiteHeader />
             <Layout 
             style={{background:'#000000',paddingTop:65}}>
         
                <Content>
                    <Route exact={exact} component={component} path={path} />
                </Content>
                </Layout>
          
        </Fragment>
    );
}


export default MainTemplate;