import { Col, Image, Row } from 'antd';
import React, { Fragment } from 'react'
import Picture from '../../assets/Icons/ic_placeholder.svg';
import UserWhite from '../../assets/Icons/user-white.svg';
import MessageWhite from '../../assets/Icons/ic_message.svg';
import Images from '../../assets/Icons/ic_images.svg';
import Videos from '../../assets/Icons/ic_videos.svg';
import Location from '../../assets/Icons/ic_locations.svg';
import Detail from '../../assets/Icons/ic_details.svg';
import Camera from '../../assets/Icons/ic_camera.svg';
import { useHistory } from 'react-router-dom';


const menu =[
    {
        title:"Profile",
        icon :UserWhite,
        path:'/profile'
    },
    {
        title:"Message",
        icon :MessageWhite,
        path:'/chat'

    },
    {
        title:"Images",
        icon :Images,
        path:'/images'

    },
    {
        title:"Videos",
        icon :Videos,
        path:'/videos'

    },
    {
        title:"Locations",
        icon :Location,
        path:'/location'

    },
  
]
const Home = () => {
    const history =useHistory();
    return (
        <Fragment>
            <section>
                <div className='text-center mt-3 '>
                    <div className='d-inline-block position-relative'>
                        <Image src={Picture} />
                        <div className='camera'>
                            <Image src={Camera} />
                        </div>
                    </div>
                    <h5 className='mt-2'>Johnny Lawrence</h5>
                </div>
            </section>
            <section className='menu-sec'>
                <ul>
                    {menu.map((item,val)=>(

                    <li key={val} onClick={()=>history.push(item.path)}>
                        <Row align='middle'>
                            <Col flex={1}>
                                <Image src={item.icon} />
                                <span>{item.title}</span>
                            </Col>
                            <Col>
                                <Image src={Detail} />
                            </Col>
                        </Row>
                    </li>
                    ))}

                </ul>
            </section>
        </Fragment>
    )
}

export default Home;
