import Avatar from 'antd/lib/avatar/avatar';
import React, { Fragment } from 'react'
import Profile from '../../assets/Icons/ic_placeholder.svg';
import Boxing from '../../assets/Icons/boxing.jpg';
import Seen from '../../assets/Icons/ic_seen_message.svg';
import { Button, Col, Image, Row } from 'antd';
import { Input } from 'antd';
import { MdOutlineAttachFile } from 'react-icons/md'

const Messages = () => {
    return (


        <Fragment>
            <div className="chat-cont">
                <section style={{ flex: 1, overflow: 'scroll',padding:'2rem 0' }} >
                    
                        <div className='d-flex'>
                            <div className='me-2'>
                                <Avatar
                                    // style={{ width: 55, height: 55 }}
                                    src={Profile}
                                    size={'large'}
                                    shape="circle" />
                            </div>
                            <div className='message-box'>
                                <div className='msg'>What are you doing?</div>
                                <div className='time'>11:54 AM</div>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className='me-2'>
                                <Avatar

                                    src={Profile}
                                    size={'large'}
                                    shape="circle" />
                            </div>
                            <div className='message-box'>
                                <div className='msg'>What are you doing?</div>
                                <div className='time'>11:54 AM</div>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className='me-2'>
                                <Avatar

                                    src={Profile}
                                    size={'large'}
                                    shape="circle" />
                            </div>
                            <div className='message-box'>
                                <div className='msg'>What are you doing?</div>
                                <div className='time'>11:54 AM</div>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className='me-2'>
                                <Avatar
                                    // style={{ width: 55, height: 55 }}
                                    src={Profile}
                                    size={'large'}
                                    shape="circle" />
                            </div>
                            <div className='message-box'>
                                <div className='msg'>What are you doing?</div>
                                <div className='time'>11:54 AM</div>
                            </div>
                        </div>
                        <div className='right-box text-right mt-4 ms-auto'>
                            <div className='img-box ms-auto'>
                                <div>I took this pic</div>
                                <Image src={Boxing} style={{ borderRadius: 4 }} />

                            </div>
                            <div className='time text-end'>11:54 AM
                                <span style={{ marginLeft: 8 }}>

                                    <Image src={Seen} />
                                </span>
                            </div>
                            <div className='text-end my-5'>
                                <div className='msg own-msg d-inline-block'>Nothing</div>
                                <div className='time'>11:54 AM</div>
                            </div>
                        </div>
                        <div className='time text-center my-5'>May 6, 2021, 11:54 AM</div>

                        <div className='d-flex'>
                            <div className='me-2'>
                                <Avatar

                                    src={Profile}
                                    size={'large'}
                                    shape="circle" />
                            </div>
                            <div className='message-box'>
                                <div className='msg'>What are you doing?</div>
                                <div className='time'>11:54 AM</div>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className='me-2'>
                                <Avatar

                                    src={Profile}
                                    size={'large'}
                                    shape="circle" />
                            </div>
                            <div className='message-box'>
                                <div className='msg'>What are you doing?</div>
                                <div className='time'>11:54 AM</div>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className='me-2'>
                                <Avatar

                                    src={Profile}
                                    size={'large'}
                                    shape="circle" />
                            </div>
                            <div className='message-box'>
                                <div className='msg'>What are you doing?</div>
                                <div className='time'>11:54 AM</div>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className='me-2'>
                                <Avatar

                                    src={Profile}
                                    size={'large'}
                                    shape="circle" />
                            </div>
                            <div className='message-box'>
                                <div className='msg'>What are you doing?</div>
                                <div className='time'>11:54 AM</div>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className='me-2'>
                                <Avatar

                                    src={Profile}
                                    size={'large'}
                                    shape="circle" />
                            </div>
                            <div className='message-box'>
                                <div className='msg'>What are you doing?</div>
                                <div className='time'>11:54 AM</div>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className='me-2'>
                                <Avatar
                                    // style={{ width: 55, height: 55 }}
                                    src={Profile}
                                    size={'large'}
                                    shape="circle" />
                            </div>
                            <div className='message-box'>
                                <div className='msg'>What are you doing?</div>
                                <div className='time'>11:54 AM</div>
                            </div>
                        </div>
                    
                </section>
                <div className='message-input-box'>
                    <Row align='middle'>
                        <Col flex={1}>
                            <Input placeholder="Type your message"
                                style={{ borderRight: 0 }}
                                className='msg-input'
                            />
                        </Col>
                        <Col>
                            <div className='d-flex align-items-center'>
                                <MdOutlineAttachFile color='#9DA9BB' />
                                <Button type='link' className='fm'>Send</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment>
    )
}

export default Messages
