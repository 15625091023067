import { Col, Image, Row } from 'antd'
import React, { Fragment } from 'react'

import Gym from '../../assets/Icons/gym.jpg';
import Gym1 from '../../assets/Icons/gym1.jpg';
import Gym2 from '../../assets/Icons/gym2.jpg';
import Gym3 from '../../assets/Icons/gym3.jpg';

import Play from '../../assets/Icons/ic_play.svg';
const gym = [Gym, Gym1, Gym2, Gym3];
const Videos = () => {
    return (

        <Fragment>

            <section className='mt-4 mb-3'>
                <Row justify='space-between' gutter={[0, 20]} className='px-2'>
                    {[1, 2, 3, 4, 5].map((item, ind) => (
                        <Fragment key={{ ind }}>
                            <Col span={7}>
                                <div>
                                    <div  className='position-relative'>
                                <div className='ply-btn'><Image src={Play}/></div>
                                <Image src={`${gym[Math.floor(Math.random() * 4)]}`} className='rounded' />
                                </div>
                                </div>
                            </Col>
                            <Col span={7}>
                                <div>
                                    <div  className='position-relative'>
                                <div className='ply-btn'><Image src={Play}/></div>
                                <Image src={`${gym[Math.floor(Math.random() * 4)]}`} className='rounded' />
                                </div>
                                </div>
                            </Col>
                            <Col span={7}>
                                <div>
                                    <div  className='position-relative'>
                                <div className='ply-btn'><Image src={Play}/></div>
                                <Image src={`${gym[Math.floor(Math.random() * 4)]}`} className='rounded' />
                                </div>
                                </div>
                            </Col>
                         
                            
                        </Fragment>
                    ))}
                </Row>
            </section>
            <section>
                <Row justify='center' >
                    <Col span={23}>
                    <button className='submit-btn mb-3'>ADD VIDEOS</button>
                    </Col>
                </Row>
            </section>
        </Fragment>
    )
}

export default Videos;
