import { Badge, Col, Divider, Image, Row } from 'antd'
import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom';
import { Input } from 'antd';
import Search from '../../assets/Icons/ic_sreach.svg';
import Profile from '../../assets/Icons/ic_placeholder.svg';
import Avatar from 'antd/lib/avatar/avatar';
const Chat = () => {
    const history =useHistory();
    return (
        <Fragment>
            <section>
                <div className='search-box px-2'>
                    <Row>
                        <Col flex={1}>
                            <Input placeholder="Search name"
                                suffix={<Image src={Search} width={25} />}
                            />
                        </Col>
                    </Row>
                </div>
            </section>
            <section>
                <Row>
                    <Col span={24}>
                        <ul className='chat-list px-2'>
                            {[1,23,4,5,6,3,3,7,7].map((item,ind)=>(

                            <li key={ind} onClick={()=>history.push('/messages')}>
                                <Row align='middle'>
                                    <Col className='me-3'>
                                        <Badge  offset={[-5, 40]}  style={{width:10,height:10}} dot={true} color={'green'} >
                                            <Avatar 
                                            style={{width:55,height:55}}
                                            src={Profile}
                                            shape="circle"/>
                                        </Badge>
                                    </Col>
                                    <Col flex={2}>
                                        <h5 className='m-0 fm'>Kit Harington</h5>
                                        <p className='m-0 fl'>Kit sent 6 photos</p>
                                        </Col>
                                    <Col>
                                    <div  className='fl me-3'>Tue</div></Col>
                                </Row>
                            </li>
                            ))}

                        </ul>
                    </Col>
                </Row>
            </section>
        </Fragment>
    )
}

export default Chat
