import { Col, Divider, Image, Row } from 'antd'
import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom';
import Banner from '../../assets/Icons/ic_brazillian_banner.svg';
import Search from '../../assets/Icons/ic_sreach.svg';
// import Cancel from '../../assets/Icons/ic_cancel.svg';
import {GrFormClose} from 'react-icons/gr'

import { Input } from 'antd';

const Services = () => {
    const history = useHistory();
    return (
        <Fragment>
            <section>

                <Row align='center'>
                    <Col span={22}>
                        <div className='bg-white py-3 rounded'>
                            <div className='px-2'>
                                <Image src={Banner} />
                            </div>
                            <div className='search-box px-2'>
                                <Row>
                                    <Col flex={1}>
                                        <Input placeholder="Search Services"
                                            suffix={<Image src={Search} width={25} />}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className='loc-list px-3 mt-3'>
                                <Row align='middle'>
                                    <Col flex={1}>
                                        <div className='text-black'>Boxing</div>
                                    </Col>
                                    <Col >
                                        <GrFormClose color='black' />
                                    </Col>
                                </Row>
                                <Divider className='my-2' />
                            </div>
                            <div className='loc-list px-3'>
                                <Row align='middle'>
                                    <Col flex={1}>
                                        <div className='text-black'>Karate</div>
                                    </Col>
                                    <Col >
                                        <GrFormClose color='black' />
                                    </Col>
                                </Row>
                                <Divider className='my-2' />
                            </div>
                            <div className='loc-list px-3'>
                                <Row align='middle'>
                                    <Col flex={1}>
                                        <div className='text-black'>Kick Boxing</div>
                                    </Col>
                                    <Col >
                                        <GrFormClose color='black' />
                                    </Col>
                                </Row>
                                <Divider className='my-2' />
                            </div>
                            <div className='loc-list px-3'>
                                <Row align='middle'>
                                    <Col flex={1}>
                                        <div className='text-black'>Taekwondo</div>
                                    </Col>
                                    <Col >
                                        <GrFormClose color='black' />
                                    </Col>
                                </Row>
                                <Divider className='my-2' />
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
            <section className='mt-3'>
                <Row justify='center' >
                    <Col span={22}>
                        <button className='submit-btn mb-3'>SAVE</button>
                    </Col>

                </Row>
            </section>

        </Fragment>
    )
}

export default Services;
