import { Button, Checkbox, Col, Form, Image, Input, Row, Space } from 'antd'
import React, { Fragment } from 'react'
import Logo from '../../assets/Icons/ic_logo_login.svg';
import User from '../../assets/Icons/ic_user.svg';
import Pass from '../../assets/Icons/ic_password.svg';
import Business from '../../assets/Icons/ic_business.svg';
import Flag from '../../assets/Icons/ic_flag.svg';
import { MdAlternateEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';



const FlagIcon =(
    <div className='d-flex align-items-center'>
    <Image src={Flag}/><span  style={{color:'#1C1C1E',marginLeft:3}}>+1</span>
    </div>
)
const Register = () => {
    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    
    return (
        <Fragment>

            <Row className='page' align='center' justify='center'>
                <Col span={22}>
                    <div className="text-center">

                        <Image
                            width={200}
                            src={Logo}
                        />

                    </div>
                    <div className='form w-100'>
                        <Form
                            name="register"
                            initialValues={{}}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item

                                name="firstName"

                            // rules={[{ required: true, message: 'Please input your username!' }]}
                            >

                                <Input placeholder='First Name'
                                    prefix={<Image src={User} />} />
                            </Form.Item>

                            <Form.Item

                                name="email"

                            // rules={[{ required: true, message: 'Please input your password!' }]}
                            >

                                <Input placeholder='Email'
                                    prefix={<MdAlternateEmail color='#C6C6C8' />} />

                            </Form.Item>
                            <Form.Item

                                name="number"

                            // rules={[{ required: true, message: 'Please input your password!' }]}
                            >

                                <Input placeholder='(617) 397 - 8483'
                                    prefix={FlagIcon} />

                            </Form.Item>
                            <Form.Item

                                name="Business Name"

                            // rules={[{ required: true, message: 'Please input your password!' }]}
                            >

                                <Input placeholder='Business Name'
                                    prefix={<Image src={Business} />} />

                            </Form.Item>
                            <Form.Item

                                name="password"

                            // rules={[{ required: true, message: 'Please input your password!' }]}
                            >

                                <Input.Password placeholder="Password"
                                    iconRender={() => <></>}
                                    prefix={<Image src={Pass} />} />

                            </Form.Item>
                            <Form.Item

                                name="password"

                            // rules={[{ required: true, message: 'Please input your password!' }]}
                            >

                                <Input.Password placeholder=" Confirm Password"
                                    iconRender={() => <></>}

                                    prefix={<Image src={Pass} />} />

                            </Form.Item>



                            <Form.Item >
                                <button className='submit-btn'>
                                SIGN UP
                                </button>
                               
                            </Form.Item>
                        </Form>

                        <div className='text-center'>Already have account ? <Link to={"/"} 
                        style={{textDecoration:'none',fontFamily:'EnnvisionM',color:'#fff'}}> Sign In</Link></div>
                    </div>
                </Col>
            </Row>

        </Fragment>
    )
}

export default Register
