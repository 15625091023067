import React, { Fragment, useState } from "react";
import { Layout } from "antd";

import { Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
const { Footer, Content } = Layout;

const PreLoginTemplate = ({ component, exact, path }) => {

  const { path: pathname } = useLocation();
  console.log(path)

  return (

    <Fragment>

      <Route exact={exact} component={component} path={path} />
    </Fragment>


  );
};

export default PreLoginTemplate;
