import { Col, Divider, Image, Row } from 'antd'
import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom';
import Banner from '../../assets/Icons/ic_brazillian_banner.svg';

const LocationDetail = () => {
    const history =useHistory();
    return (
        <Fragment>
            <section>

            <Row align='center'>
                <Col span={22}>
                    <div className='bg-white py-3 rounded'>
                        <div className='px-2'>
                            <Image src={Banner} />
                        </div>
                        <div className='loc-list px-3'>

                            <div className='loc-title'>Name</div>
                            <div className='loc-sub-title'>Brazilian Top Team Long Beach Jiu</div>
                            <Divider className='my-2' />
                        </div>
                        <div className='loc-list px-3'>

                            <div className='loc-title'>Address 1</div>
                            <div className='loc-sub-title'>2680 Dawson Ave</div>
                            <Divider className='my-2' />
                        </div>
                        <div className='loc-list px-3'>

                            <div className='loc-title'>Address 2</div>
                            <div className='loc-sub-title'>Suite 120</div>
                            <Divider className='my-2' />
                        </div>
                        <div className='loc-list px-3'>

                            <div className='loc-title'>City</div>
                            <div className='loc-sub-title'>Signal Hill</div>
                            <Divider className='my-2' />
                        </div>
                        <div className='loc-list px-3'>

                            <div className='loc-title'>State</div>
                            <div className='loc-sub-title'>California</div>
                            <Divider className='my-2' />
                        </div>
                        <div className='loc-list px-3'>

                            <div className='loc-title'>Country</div>
                            <div className='loc-sub-title'>United States</div>
                            <Divider className='my-2' />
                        </div>
                        <div className='loc-list px-3'>

                            <div className='loc-title'>Zip Code</div>
                            <div className='loc-sub-title'>90755</div>
                            <Divider className='my-2' />
                        </div>
                        <div className='loc-list px-3'>

                            <div className='loc-title'>Phone</div>
                            <div className='loc-sub-title'>+1 (617) 397 - 8483</div>
                            <Divider className='my-2' />
                        </div>
                    </div>
                </Col>
            </Row>
            </section>
            <section className='mt-3'>
                <Row justify='center' >
                    <Col span={22}>
                    <button onClick={()=>history.push('/edit-location')} className='submit-btn mb-3'>EDIT LOCATION</button>
                    </Col>
                    <Col span={22}>
                    <button onClick={()=>history.push('/services')} className='submit-btn green-bg mb-3'>SERVICES</button>
                    </Col>
                </Row>
            </section>
            
        </Fragment>
    )
}

export default LocationDetail
